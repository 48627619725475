export const role = [
  {
    value: 0,
    label: 'user',
  },
  {
    value: 1,
    label: 'hr',
  },
  {
    value: 2,
    label: 'company',
  },
];

export const hostImg = 'https://bachnguyencoder.id.vn/images/';

export const listMangaSkeleton = new Array(25).fill(0);
